import React from 'react'
import Card from './DashCard'
import Button from 'react-bootstrap/Button'
import { Spinner } from 'react-bootstrap';
import "../css/dashboard.css"
import constants from '../constants'

export default function Dashboard(props) {
    const [clip, setClip] = React.useState('');
    return (
        <div id="dashboard">
            <h1 className='dash-heading'>Dashboard</h1>
            <div className='outline-div'>
                <div className='dashboard'>
                    <Card title="Withdrawable(Divs+Refs)" credit={`${props.total_withdrawable_by_user ? props.total_withdrawable_by_user : 0} MATIC`} />
                    <Card title="Total Invested" credit={`${props.total_invested_by_user ? props.total_invested_by_user : 0} MATIC`} />
                    <Card title="Total Withdrawl" credit={`${props.total_withdrawn_by_user ? props.total_withdrawn_by_user : 0} MATIC`} />
                    <Card title="Total Referal Reward" credit={`${props.total_referral_bonus_for_user ? props.total_referral_bonus_for_user : 0} MATIC`} />
                </div>
                <div className=' dashboard custom-div'>
                    {
                        props.withdrawl_status && props.nextPayout < Date.now() ?
                            props.withdraw_loading ?
                                <Button
                                    className="buttons-withdraw"
                                    variant="outline-light"
                                    size="md"
                                >
                                    <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border" />
                                </Button>
                                :
                                props.total_invested_by_user > 0 ?
                                    <Button
                                        className="buttons-withdraw"
                                        variant="outline-light"
                                        size="md"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.withdraw();
                                        }}>
                                        WITHDRAW
                                    </Button>
                                    :
                                    <Button
                                        className="buttons-withdraw"
                                        variant="outline-light"
                                        size="md"
                                        href='#calc'
                                    >
                                        Click here to invest
                                    </Button>
                            :
                            <Button
                                className="buttons-withdraw"
                                variant="outline-light"
                                size="md"
                            >
                                Next withdrawl on {new Date(props.nextPayout).toDateString()}
                            </Button>
                    }
                    {
                        props.withdraw_tx !== "" && props.withdraw_tx !== undefined ?
                            <div className='custom-div'>
                                <Button
                                    className='buttons-withdraw'
                                    variant="outline-light"
                                    size="md"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`${constants.EXPLORER_URL}/tx/${props.withdraw_tx}`, "_blank");
                                    }}
                                >VIEW TRANSACTION</Button>
                            </div>
                            :
                            null
                    }
                </div>
                <div className='dashboard button-div'>

                    <div className='custom-div'>
                        <label >
                            Referal Link :
                            <span className="ref-link"
                                onClick={() => {
                                    navigator.clipboard.writeText(`${constants.WEBSITE_URL}?ref=${props.accounts[0]}`)
                                    setClip("Copied to Clipboard!")
                                }}>
                                <span style={{ fontWeight: 'bold' }} > {props.total_invested_by_user > 0 ? "(Click to Copy)" : "(Invest to get your referral link)"}</span>
                                {props.total_invested_by_user > 0 ?
                                    < span
                                        href=''
                                    >
                                        {" "} &nbsp;<i className="fas fa-copy copy-link"></i>
                                    </span>
                                    : null
                                }
                            </span>
                        </label>
                        <br /><span>{clip}</span>
                    </div>
                </div>
                <div className='dashboard'>
                    <Card title="1Lvl" credit={`${props.referral_structure_for_user ? props.referral_structure_for_user[0] : 0} referrals`} reward="(9% Ref. reward)" />
                    <Card title="2Lvl" credit={`${props.referral_structure_for_user ? props.referral_structure_for_user[1] : 0} referrals`} reward="(4.5% Ref. reward)" />
                    <Card title="3Lvl" credit={`${props.referral_structure_for_user ? props.referral_structure_for_user[2] : 0} referrals`} reward="(3% Ref. reward)" />
                    <Card title="4Lvl" credit={`${props.referral_structure_for_user ? props.referral_structure_for_user[3] : 0} referrals`} reward="(1.5% Ref. reward)" />
                    <Card title="5Lvl" credit={`${props.referral_structure_for_user ? props.referral_structure_for_user[4] : 0} referrals`} reward="(0.7% Ref. reward)" />
                </div>
            </div>
        </div >
    )
}
