import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import polyLogo from "../assets/polylogo.png";
import "../css/home.css";

export default function Home(props) {
  return (
    <div id="home" className="cont-div">
      <Container fluid="md">
        <div className="">
          <Row className="des-home">
            <Col>
              <h1>
                Stable and Profitable Yield Farming Dapp on{" "}
                <p className="b-chain">Polygon (Matic Network)</p>
              </h1>
            </Col>
          </Row>
          <Row className="des-home">
            <Col>
              <p>
                Earn from <span className="b-chain">130%</span> upto{" "}
                <span className="b-chain">250%</span> total profits in 10 to 34 days
                <br />5 Levels of Referral Rewards
              </p>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>
              <Button
                className="buttons-invest"
                variant="outline-light"
                size="lg"
                href="#calc"
              >
                Invest
              </Button>
              <Button
                className="buttons-invest"
                variant="outline-light"
                size="lg"
                href='#dashboard'
              >
                View Dashboard
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col className="btn-1">
              <Button className="buttons-invest"
                variant="light" size="lg">
                Total Invested:{" "}
                {props.value1} <span className="text-white"> MATIC </span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="btn-1">
              <Button className="buttons-invest"
                variant="light" size="lg">
                Total Referal Reward:{" "}
                {props.value2} <span className="text-white"> MATIC </span>
              </Button>
            </Col>
          </Row>
        </div>
      </Container >

      <Container fluid="md">
        <img
          alt="polygon-logo"
          src={polyLogo}
          width="60%"
          height="60%"
          className="d-inline-block image-logo i-logo"
        />
      </Container>
    </div >
  );
}
