import dotenv from 'dotenv';
dotenv.config();

const constants = {
  EXPLORER_URL: process.env.REACT_APP_EXPLORER_URL,
  PROVIDER: process.env.REACT_APP_PROVIDER,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
  NETWORK_ID: process.env.REACT_APP_NETWORK_ID,
  CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
  TELEGRAM_LINK: process.env.REACT_APP_TELEGRAM_LINK,
};

export default constants;