import React from "react";
import Button from "@mui/material/Button";
import constants from "../constants";
import IconButton from "@mui/material/IconButton";
import TelegramIcon from "@mui/icons-material/Telegram";
import "../css/footer.css";

export default function footer(props) {
  let currentTimeDate = new Date();
  let CurrentYear = currentTimeDate.getFullYear();
  return (
    <div id="footer">
      <div className="footer">
        <p>&#169; &nbsp;{CurrentYear} Polygon Jupiter</p>
        <div className="btn-foot-div">
          <Button variant="contained" className="btn-footer" size="md">
            Audit
          </Button>
        </div>
        <div className="btn-foot-div">
          <a
            href={`${constants.EXPLORER_URL}/address/${constants.CONTRACT_ADDRESS}`}
            target={"_blank"}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" className="btn-footer" size="md" >
              Smart Contract
            </Button>
          </a>
        </div>

        <div className="btn-foot-div">
          <Button variant="contained" className="btn-footer" size="md" onClick={() => props.toggleFaq()}>
            FAQ's
          </Button>
        </div>
        <div className="logodiv">
          <a
            className="nav-link1"
            href={constants.TELEGRAM_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <a
              href={constants.TELEGRAM_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                variant="contained"
                sx={{ color: "white" }}
                className="btn-footer-tele"
                size="small"
              >
                <TelegramIcon />
              </IconButton>
            </a>
          </a>
        </div>
      </div>
    </div>
  );
}
