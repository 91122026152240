import React from 'react'

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
export default function List(props) {
    return (
        <div>
            <ListItem  >
                <ListItemText
                    primary={props.primary}
                    secondary={props.secondary}
                />
            </ListItem>
        </div>
    )
}
