import React from 'react'
import Card from 'react-bootstrap/Card'
import '../css/dashcard.css'
export default function DashCard(props) {
    return (
        <div className="card-div" >
            <Card bg="light" border="light" style={{ width: '13rem' }} text="dark" >

                <Card.Body>
                    <Card.Text className="card-title">
                        {props.title}
                    </Card.Text>
                    <Card.Text>
                        {props.credit} {props.reward}
                    </Card.Text>

                </Card.Body>
            </Card>
        </div >
    )
}
