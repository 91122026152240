import React, { Component } from "react";
import PolygonJupiter from "./contracts/PolygonJupiter.json";
import getWeb3 from "./getWeb3";
import Nav1 from "./components/navbar";
import Home from "./components/Home"
import Dashboard from "./components/Dashboard";
import CalculateProfit from "./components/CalculateProfit";
import Footer from "./components/footer";
// import BigNumber from "big-number";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import constants from "./constants";

const nullAddress = "0x0000000000000000000000000000000000000000";

class App extends Component {
  constructor() {
    super();
    this.state = {
      // contractInfo
      total_referral_bonus: 0,
      total_invested: 0,
      total_withdrawn: 0,

      // investorInfo
      total_invested_by_user: 0,
      total_withdrawn_by_user: 0,
      total_withdrawable_by_user: 0,
      total_referral_bonus_for_user: 0,
      referral_structure_for_user: [0, 0, 0, 0, 0],

      // default values
      default_investment_amount: 100,

      // investmentDetails
      investment_tarif: 30,
      referral_address: nullAddress,

      // invested details
      invested_tx: "",
      withdraw_tx: "",
      invest_loading: false,
      withdraw_loading: false,

      // depositIndexInfo
      depositIndex: 0,
      depositIndex_tarif: 0,
      depositIndex_amount: 0,
      depositIndex_time: 0,
      fetchedDepositDetails: false,

      //withdrawlStatus
      withdrawl_status: false,
      nextPayout: 0,

      //web3
      web3: null,
      accounts: null,
      network: null,
      contractInstance: null,

      //toggle
      toggle: false,
    }
    this._ismounted = false;
  }

  async componentDidMount() {
    this._ismounted = true;
    this.onSwitch();
    try {
      var web3 = await getWeb3();
      var accounts = await web3.eth.getAccounts();
      var network = await web3.eth.net.getId();

      if (network.toString() !== constants.NETWORK_ID) {
        alert("Please switch to polygon network and refresh the page");
      }
      var contractInstance = new web3.eth.Contract(
        PolygonJupiter.abi,
        PolygonJupiter.networks[network].address
      );
      this._ismounted && this.setState({
        web3,
        accounts,
        network,
        contractInstance,
      })
    } catch (error) {
      console.log(error.message);
    }
    this.extractReferralAddress();
    this.getContractInfo();
    this.getInvestorInfo();
    this.getWithdrawlStatus();
  }

  extractReferralAddress = (url) => {
    let referral_address = nullAddress;
    url = new URL(window.location.href);
    if (url.searchParams.get("ref")) {
      referral_address = url.searchParams.get("ref");
    }
    this._ismounted && this.setState({ referral_address });
  }

  onSwitch = () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload();
      })

      window.ethereum.on('networkChanged', function (networkId) {
        window.location.reload();
      })
    }
  }

  convertToMatic = (amount) => {
    amount = amount.toString();
    return this.state.web3.utils.fromWei(amount, "ether");
  }

  getContractInfo = async () => {
    try {
      const contractInfo = await this.state.contractInstance.methods.contractInfo().call();
      this._ismounted && this.setState({
        total_invested: contractInfo._invested,
        total_withdrawn: contractInfo._withdrawn,
        total_referral_bonus: contractInfo._referral_bonus,
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  getWithdrawlStatus = async () => {
    try {
      const withdrawlStatus = await this.state.contractInstance.methods.withdrawStatus().call({ from: this.state.accounts[0] });
      if (withdrawlStatus) {
        this._ismounted && this.setState({
          withdrawl_status: withdrawlStatus._status,
          nextPayout: Number(withdrawlStatus._nextPayout) * 1000,
        });
      }
    }
    catch (error) {
      console.log(error.message);
    }
  }

  getInvestorInfo = async () => {
    try {
      if (!this.state.web3.utils.isAddress(this.state.accounts[0])) return;
      const investorInfo = await this.state.contractInstance.methods.investorInfo(this.state.accounts[0]).call({ from: this.state.accounts[0] });
      this._ismounted && this.setState({
        total_invested_by_user: investorInfo.total_invested,
        total_withdrawn_by_user: investorInfo.total_withdrawn,
        total_withdrawable_by_user: investorInfo.for_withdrawal,
        total_referral_bonus_for_user: investorInfo.total_referral_bonus,
        referral_structure_for_user: investorInfo.structure,
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  invest = async (toInvest, days, accounts) => {
    if (days < 10 || days > 34) {
      alert("Deposit duration should be between 10 and 34 days");
      return;
    }
    if (toInvest < 10) {
      alert("Investment amount should be greater than 10");
      return;
    }
    if (!accounts) {
      alert("Please connect your wallet");
      return;
    }

    try {
      var ref = this.state.web3.utils.isAddress(this.state.referral_address) ? this.state.referral_address : nullAddress;
      const value = this.state.web3.utils.toWei(toInvest.toString(), 'ether');
      this._ismounted && this.setState({
        invest_loading: true,
      });
      const tx = await this.state.contractInstance.methods.deposit(days, ref).send({ from: accounts[0], value });
      this._ismounted && this.setState({
        invested_tx: tx.transactionHash,
        invest_loading: false,
      });
    } catch (error) {
      this._ismounted && this.setState({
        invest_loading: false,
      });
      console.log(error);
    }
    this.getContractInfo();
    this.getInvestorInfo();
  }

  withdraw = async () => {
    try {
      this._ismounted && this.setState({
        withdraw_loading: true,
      });
      const gas = await this.state.contractInstance.methods.withdraw().estimateGas({ from: this.state.accounts[0] });
      const tx = await this.state.contractInstance.methods.withdraw().send({ from: this.state.accounts[0], gas });
      this._ismounted && this.setState({
        withdraw_tx: tx.transactionHash,
        withdraw_loading: false,
      });
    } catch (error) {
      this._ismounted && this.setState({
        withdraw_loading: false,
      });
      console.log(error);
    }
    this.getContractInfo();
    this.getInvestorInfo();
    this.getWithdrawlStatus();
  }

  getDepositInfo = async () => {
    try {
      const depositInfo = await this.state.contractInstance.methods.depositInfo(this.state.depositIndex).call({ from: this.state.accounts[0] });
      if (depositInfo) {
        this._ismounted && this.setState({
          fetchedDepositDetails: true,
          depositIndex_tarif: depositInfo._tarif,
          depositIndex_amount: depositInfo._amount,
          depositIndex_time: new Date(depositInfo._time),
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  getMaxInvestment = async () => {
    if (!this.state.web3) return 0;
    try {
      const maxInvestment = await this.state.web3.eth.getBalance(this.state.accounts[0]);
      return this.convertToMatic(maxInvestment)
    }
    catch (error) {
      console.log(error.message);
      return 0;
    }
  }

  toggleFaq = () => {
    this._ismounted && this.setState({
      toggle: !this.state.toggle
    });
  }

  render() {
    if (!this.state.web3) {
      return (
        <div className="App" >
          <Nav1 />
          <Home value1={0} value2={0} />
          <CalculateProfit />
          <Dashboard />
          <Footer />
        </div>
      );
    } else {
      return (
        <div className="App" >
          <Nav1 toggle={this.state.toggle} toggleFaq={this.toggleFaq} />
          <Home value1={this.convertToMatic(this.state.total_invested)} value2={this.convertToMatic(this.state.total_referral_bonus)} />
          <CalculateProfit
            invested_tx={this.state.invested_tx}
            invest_loading={this.state.invest_loading}
            invest={this.invest}
            accounts={this.state.accounts}
            getMaxInvestment={this.getMaxInvestment}
          />
          <Dashboard
            total_invested_by_user={this.convertToMatic(this.state.total_invested_by_user)}
            total_withdrawn_by_user={this.convertToMatic(this.state.total_withdrawn_by_user)}
            total_withdrawable_by_user={this.convertToMatic(this.state.total_withdrawable_by_user)}
            total_referral_bonus_for_user={this.convertToMatic(this.state.total_referral_bonus_for_user)}
            referral_structure_for_user={this.state.referral_structure_for_user}
            withdraw={this.withdraw}
            withdraw_tx={this.state.withdraw_tx}
            withdraw_loading={this.state.withdraw_loading}
            accounts={this.state.accounts}
            withdrawl_status={this.state.withdrawl_status}
            nextPayout={this.state.nextPayout}
          />
          <Footer toggleFaq={this.toggleFaq} />
        </div>
      )
    }
  }
}

export default App;
