import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container, Modal, Nav } from "react-bootstrap";
import polyLogo from "../assets/polylogo.png";
import "../css/navbar.css";
import constants from "../constants";
import IconButton from "@mui/material/IconButton";
import TelegramIcon from "@mui/icons-material/Telegram";

const connect = async () => {
  if (window.ethereum) {
    const connect = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return connect;
  } else if (window.web3) {
    const connect = await window.web3.eth.requestAccounts();
    return connect;
  } else {
    alert("Please install metamask and then refresh the page");
  }
};

const isConnected = () => {
  if (window.ethereum) {
    return window.ethereum.isConnected();
  }
  return false;
};

const alreadyConnected = () => {
  alert("You wallet is already connected");
};

export default function Nav1(props) {
  return (
    <div>
      <Navbar collapseOnSelect expand="md" fixed="top" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand className="head-brand" href="#home">
            <img
              alt="polygon-logo"
              src={polyLogo}
              width="38"
              height="38"
              className="d-inline-block align-top image-logo"
            />
            {"  "}Polygon Jupiter
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className="nav-link1" href="#calc">
                Calculator
              </Nav.Link>
              <Nav.Link className="nav-link1" href="#dashboard">
                Dashboard
              </Nav.Link>

              {/* <Nav.Link className="nav-link1" href="#viewdeposit">
                View Deposit
              </Nav.Link> */}
              <Nav.Link className="nav-link1" onClick={() => props.toggleFaq()}>
                FAQs
              </Nav.Link>

              <Nav.Link
                className="nav-link1"
                onClick={(e) =>
                  isConnected() ? alreadyConnected() : connect()
                }
              >
                {isConnected() ? "Connected" : "Connect"}
              </Nav.Link>
              <Nav.Link
                className="nav-link1"
                href={constants.TELEGRAM_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  variant="contained"
                  sx={{ color: "white" }}
                  className="btn-nav-tele"
                  size="small"
                >
                  <TelegramIcon />
                </IconButton>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={props.toggle} onHide={() => props.toggleFaq()}>
        <Modal.Header closeButton>
          <Modal.Title className="faq">FAQs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="faq">
            <h5>What is Polygon Jupiter?</h5>
            <p>
              Polygon Jupiter is a Secure, Stable and Profitable yield farming dapp on Polygon (prev. Matic Network). It allows you to invest your MATIC tokens with a stable profitable yield amount.
            </p>
            <h5>How do I invest?</h5>
            <p>
              You can invest by selecting the amount and days and then clicking on the invest button in the Calculate Profit section. And voila! You will have made a successful investment.
            </p>
            <h5>How do I withdraw?</h5>
            <p>
              You can withdraw by clicking on the withdraw button in the Dashboard section and voila! You will receive all the withdrawable amount in your connected wallet address.
            </p>
            <h5>How frequently can I withdraw?</h5>
            <p>
              After your first withdrawal, you will be able to withdraw every 10 days.
            </p>
            <h5>What is the minimum investment amount?</h5>
            <p>
              You can invest a minimum of 10 Matic.
            </p>
            <h5>How do I view my earnings?</h5>
            <p>
              You can view your withdrawable earnings in the Dashboard section of this website.
            </p>
            <h5>How do I view my investments?</h5>
            <p>
              You can view your invested amount in the Dashboard section of this website.
            </p>
            <h5>How do I connect with the community?</h5>
            <p>
              You can join us here on  <a href={constants.TELEGRAM_LINK} target={"_blank"} className="social-faq"> Telegram </a> and tweet us here on <a href="https://twitter.com/PolygonJupiter" target={"_blank"} className="social-faq">Twitter</a>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
