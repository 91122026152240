import React from "react";
import Form from "react-bootstrap/Form";
import Slider from "@mui/material/Slider";
import { Spinner } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import IList from "./List";
// import { useFormControl } from '@mui/material/FormControl';
import "../css/calculateprofit.css";
import constants from "../constants";

const calculateProfitPercent = (days) => {
  days = days - 10;
  var profit = 130 + days * 5;
  return profit;
};

const calculateProfit = (investmentAmount, days) => {
  var percent = calculateProfitPercent(days);
  var profit = investmentAmount * (percent / 100);
  return profit;
};

export default function CalculateProfit(props) {
  const [investmentAmount, setInvestmentAmount] = React.useState(100);
  const [days, setDays] = React.useState(20);
  return (
    <div id="calc">
      <h1 className="cal-heading">Calculate Profit</h1>
      <Form>
        <div className="outline-div outer-div">
          <div className="slider-div">
            <div>
              <div className="heading">
                <h4>Deposit Period: {days} days</h4>
              </div>
              <Box width={"100%"}>
                <Slider
                  aria-label="Always visible"
                  step={1}
                  value={days}
                  valueLabelDisplay="auto"
                  min={10}
                  max={34}
                  onChange={(event, newValue) => {
                    setDays(newValue);
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiSlider-thumb": {
                      borderRadius: "50%",
                      backgroundColor: "#8247e5",
                    },
                    "& .MuiSlider-track": {
                      backgroundColor: "#8247e5",
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "#8247e5",
                    },
                    height: 12,

                  }}
                />
              </Box>
            </div>
            <div className="heading">
              <TextField
                fullWidth
                label={"Amount in MATIC"}
                placeholder="MATIC"
                id="margin-dense"
                margin="dense"
                type="number"
                value={investmentAmount}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  setInvestmentAmount(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                className="max-button"
                variant="outline-light"
                onClick={async () => {
                  var amount = await props.getMaxInvestment();
                  setInvestmentAmount((Number(amount) - 0.05).toFixed(4) > 0 ? (Number(amount) - 0.05).toFixed(4) : 0);
                }}
                size="sm"
              >Max</Button>
            </div>

          </div>
          <div className="heading">
            <List className="list-div">
              <IList
                primary="Daily ROI:
                                    "
                secondary={`${(calculateProfitPercent(days) / days).toFixed(
                  2
                )} %`}
              />
              <IList
                primary="Total Profit:
                            "
                secondary={`${calculateProfitPercent(days)} %`}
              />
              <IList
                primary={`In ${days} days you will earn:`}
                secondary={`${calculateProfit(investmentAmount, days).toFixed(
                  2
                )} MATIC`}
              />
            </List>
          </div>
          <div className="custom-div">
            <Button
              className="buttons-invest"
              variant="outline-light"
              size="md"
              onClick={() =>
                props.invest(investmentAmount, days, props.accounts)
              }
            >
              {props.invest_loading ? (
                <Spinner
                  as="span"

                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                "Invest"
              )}
            </Button>
            {props.invested_tx !== "" && props.invested_tx !== undefined ? (
              <Button
                className="buttons-invest"
                variant="outline-light"
                size="md"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${constants.EXPLORER_URL}/tx/${props.invested_tx}`,
                    "_blank"
                  );
                }}
              >
                VIEW TRANSACTION
              </Button>
            ) : null}
          </div>
        </div>
      </Form>
    </div>
  );
}
